<template>
  <div class="model-box" ref="model-box"
      @mousewheel.stop="onMouseWheel"
      @mousedown="onMouseDown"
      @mousemove="onMouseMove"
      @mouseleave="onMouseLeave"
      @mouseup="onMouseUp">
      <div class="elasticity-box" :draggable="true" :style="{height: boxH+'px', transform: imageTransform }">
        <div class="elasticity-box-item">
          <div class="elasticity-box-item-bs" :class="{'elasticity-box-item-bs-left': index == 0, 'elasticity-box-item-bs-centre': index != 0 && index != (topList.length - 1), 'elasticity-box-item-bs-right': index == (topList.length - 1) }" v-for="item,index in topList" :key="index">
            <div class="elasticity-box-item-bs-z">
              <div class="elasticity-box-item-bs-z-item" v-for="item2,index2 in item.productLine" :key="index2">
                <div class="elasticity-box-item-bs-z-item-t" style="cursor: pointer;" @click="handleNavi('/BigScreenDisplayProducts')">{{ item2.name || '--' }}</div>
                <div class="elasticity-box-item-bs-z-item-c" style="margin-top: 0">
                  <div class="elasticity-box-item-bs-z-item-c-text">保费</div>
                  <div class="elasticity-box-item-bs-z-item-c-num">{{ item2.indemnity || '--' }}</div>
                </div>
                <div class="elasticity-box-item-bs-z-item-c">
                  <div class="elasticity-box-item-bs-z-item-c-text">赔款</div>
                  <div class="elasticity-box-item-bs-z-item-c-num">{{ item2.premium || '--' }}</div>
                </div>
                <div class="elasticity-box-item-bs-z-item-c">
                  <div class="elasticity-box-item-bs-z-item-c-text">满期赔付率</div>
                  <div class="elasticity-box-item-bs-z-item-c-num">{{ item2.maturityLossRatio || '--' }}</div>
                </div>
              </div>
            </div>
            <div class="elasticity-box-item-bs-t">
              <img class="elasticity-box-item-bs-t-icon" v-if="item.url" :src="item.url" alt="" srcset="">
              <img class="elasticity-box-item-bs-t-icon" v-else src="https://gimg3.baidu.com/search/src=http%3A%2F%2Fpics4.baidu.com%2Ffeed%2F5d6034a85edf8db17bb89f6e661d7459574e74f6.jpeg%40f_auto%3Ftoken%3Dd30cc63c7f74b152dd311b90254ec036&refer=http%3A%2F%2Fwww.baidu.com&app=2021&size=f360,240&n=0&g=0n&q=75&fmt=auto?sec=1709830800&t=dca38a5237a0a4c3a05dc33a40688272" alt="" srcset="">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div :style="{width: vw +'px'}" class="elasticity-box-title">
          <img class="elasticity-box-title-icon" src="../../../../assets/images/bigScreen/cooperation/title.png" alt="" srcset="">
        </div>
        <div class="elasticity-box-item">
          <div class="elasticity-box-item-bs elasticity-box-item-bs2" :class="{'elasticity-box-item-bs-left2': index == 0, 'elasticity-box-item-bs-centre2': index != 0 && index != (downList.length - 1), 'elasticity-box-item-bs-right2': index == (downList.length - 1)}" v-for="item,index in downList" :key="index">
            <div class="elasticity-box-item-bs-t elasticity-box-item-bs2-t">
              <img class="elasticity-box-item-bs-t-icon" v-if="item.url" :src="item.url" alt="" srcset="">
              <img class="elasticity-box-item-bs-t-icon" v-else src="https://gimg3.baidu.com/search/src=http%3A%2F%2Fpics4.baidu.com%2Ffeed%2F5d6034a85edf8db17bb89f6e661d7459574e74f6.jpeg%40f_auto%3Ftoken%3Dd30cc63c7f74b152dd311b90254ec036&refer=http%3A%2F%2Fwww.baidu.com&app=2021&size=f360,240&n=0&g=0n&q=75&fmt=auto?sec=1709830800&t=dca38a5237a0a4c3a05dc33a40688272" alt="" srcset="">
              {{ item.name }}
            </div>
            <div class="elasticity-box-item-bs-z elasticity-box-item-bs2-z">
              <div class="elasticity-box-item-bs-z-item" v-for="item2,index2 in item.productLine" :key="index2">
                <div class="elasticity-box-item-bs-z-item-t" style="cursor: pointer;" @click="handleNavi('/BigScreenDisplayProducts')">{{ item2.name || '--' }}</div>
                <div class="elasticity-box-item-bs-z-item-c" style="margin-top: 0">
                  <div class="elasticity-box-item-bs-z-item-c-text">保费</div>
                  <div class="elasticity-box-item-bs-z-item-c-num">{{ item2.indemnity || '--' }}</div>
                </div>
                <div class="elasticity-box-item-bs-z-item-c">
                  <div class="elasticity-box-item-bs-z-item-c-text">赔款</div>
                  <div class="elasticity-box-item-bs-z-item-c-num">{{ item2.premium || '--' }}</div>
                </div>
                <div class="elasticity-box-item-bs-z-item-c">
                  <div class="elasticity-box-item-bs-z-item-c-text">满期赔付率</div>
                  <div class="elasticity-box-item-bs-z-item-c-num">{{ item2.maturityLossRatio || '--' }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <div class="draggable-o">
      <div class="draggable-o-r" @click="handleReplacement">
        <img src="../../../../assets/images/bigScreen/cooperation/default.png" alt="" srcset="">
        <div>默认</div>
      </div>
      <div class="draggable-o-r" @click="multiple += 0.2">
        <img src="../../../../assets/images/bigScreen/cooperation/big.png" alt="" srcset="">
        <div>放大</div>
      </div>
      <div @click="multiple > 0.4 ? multiple -= 0.2 : multiple = 0.2">
        <img src="../../../../assets/images/bigScreen/cooperation/little.png" alt="" srcset="">
        <div>缩小</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      vw: 0,
      vh: 0,
      boxH: 0,
      boxW: 0,
      multiple: 1,
      isDragging: false,
      dragStartX: 0,
      dragStartY: 0,
      imageLeft: 0,
      imageTop: 0,
      topList: [],
      downList: []
    }
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    options: {
      handler(v) {
        this.$nextTick(() => {
          let list = JSON.parse(JSON.stringify(v));
          this.topList = list.splice(0, parseInt(list.length / 2));
          this.downList = list;
          // console.log(this.topList, this.downList);
        });
      },
      deep: true,
      immediate: false,
    },
  },
  created() {
    this.init();
  },
  computed: {
    imageTransform() {
      return `translate(${this.imageLeft}px, ${this.imageTop}px) scale(${this.multiple})`;
    },
  },
 methods: {
    init() {
      this.$nextTick(() => {
        this.vw = this.$refs["model-box"].offsetWidth;
        this.vh = this.$refs["model-box"].offsetHeight;
        this.boxH = this.$refs["model-box"].offsetHeight;
      });
      // })
    },
    resize(newRect) {
      // console.log(newRect);
        // this.boxW = newRect.offsetWidth;
        // this.boxH = newRect.offsetHeight;
    },
    onMouseWheel(event) {
      event.preventDefault();
      const wheelDelta = event.deltaY;
      if (wheelDelta > 0 && this.multiple > 0.4) {
        this.multiple -= 0.1;
      } else if (wheelDelta < 0) {
        this.multiple += 0.1;
      }
    },
    onMouseDown(event) {
      this.isDragging = true;
      this.dragStartX = event.clientX;
      this.dragStartY = event.clientY;
      this.startImageX = this.imageLeft;
      this.startImageY = this.imageTop;
    },
    onMouseMove(event) {
      event.preventDefault();
      if (this.isDragging) {
        const offsetX = event.clientX - this.dragStartX;
        const offsetY = event.clientY - this.dragStartY;
        this.imageLeft = this.startImageX + offsetX;
        this.imageTop = this.startImageY + offsetY;
      }
    },
    onMouseUp() {
      this.isDragging = false;
    },
    onMouseLeave() {
      this.isDragging = false;
    },
    handleReplacement() {
      this.dragStartX = 0;
      this.dragStartY = 0;
      this.imageLeft = 0;
      this.imageTop = 0;
      this.multiple = 1;
    },
    handleNavi(path){
      this.$router.push({
        path
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.model-box {
  position: relative;
  height: 100%;
  // overflow: auto;
  ::v-deep .active:before{
    border: none !important;
    outline: none !important;
  }
  .elasticity-box {
    display: flex;
    flex-direction: column;
    &-item {
      flex: 1;
      display: flex;
      justify-content: center;
      &-bs{
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        &-z {
          position: relative;
          display: flex;
          height: 2.23rem;
          margin: 0 0.16rem;
          // padding: 0.12rem 0;
          border: 0.01rem dashed #A3EDFF;
          &-item{
            margin: 0.12rem;
            &-t {
              width: 1.1rem;
              height: 0.34rem;
              line-height: 0.34rem;
              text-align: center;
              padding: 0 0.06rem;
              white-space: nowrap; /* 不换行 */
              overflow: hidden; /* 溢出部分隐藏 */
              text-overflow: ellipsis; /* 显示省略号 */
              font-size: 0.14rem;
              color: #1CB5C8;
              margin-bottom: 0.14rem;
              background: #004CA8;
              border-radius: 0.05rem;
            }
            &-t:hover {
              color: #fff;
              background: #00A8FF;
            }
            &-c{
              font-size: 0.14rem;
              color: #FDFFFF;
              margin-top: 0.25rem;
              &-text {

              }
              &-num {

              }
            }
          }
        }
        &-z::before {
            content: '';
            position: absolute;
            height: 0.2rem;
            width: 0.01rem;
            background-color: #5584A4;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0.22rem);
          }
        &-t {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: fit-content;
          height: 0.56rem;
          font-weight: bold;
          font-size: 0.16rem;
          color: #22DAEB;
          margin: 0.2rem auto 0.35rem;
          padding: 0 0.18rem;
          // margin-bottom: 0.35rem;
          // margin-top: 0.2rem;
          border: 0.01rem solid #5CAEFF;
          &-icon {
            width: 0.32rem;
            height: 0.32rem;
            margin-right: 0.1rem;
            border-radius: 50%;
          }
        }
        &-t::before {
            content: '';
            position: absolute;
            height: 0.33rem;
            width: 0.01rem;
            background-color: #fff;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0.35rem);
        }
        &-centre::before {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0.01rem;
          background-color: rgba(255, 255, 255, .5);
        }
        &-left::before {
          position: absolute;
          content: '';
          bottom: 0;
          left: 50%;
          width: 50%;
          height: 0.01rem;
          background-color: rgba(255, 255, 255, .5);
        }
        &-right::before {
          position: absolute;
          content: '';
          bottom: 0;
          right: 50%;
          width: 50%;
          height: 0.01rem;
          background-color: rgba(255, 255, 255, .5);
        }
        &-centre2::before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 100%;
          height: 0.01rem;
          background-color: rgba(255, 255, 255, .5);
        }
        &-left2::before {
          position: absolute;
          content: '';
          top: 0;
          left: 50%;
          width: 50%;
          height: 0.01rem;
          background-color: rgba(255, 255, 255, .5);
        }
        &-right2::before {
          position: absolute;
          content: '';
          top: 0;
          right: 50%;
          width: 50%;
          height: 0.01rem;
          background-color: rgba(255, 255, 255, .5);
        }
      }
      &-bs2 {
        justify-content: flex-start;
        &-z::before {
          display: none !important;;
            top: 0;
            left: 50%;
            transform: translate(-50%, -0.22rem);
        }
        &-t {
          margin: 0.35rem auto 0.2rem;
        }
        &-t::before {
            content: '';
            position: absolute;
            height: 0.33rem;
            width: 0.01rem;
            background-color: #fff;
            top: 0;
            left: 50%;
            transform: translate(-50%, -0.35rem);
        }
        &-t::after {
            content: '';
            position: absolute;
            height: 0.2rem;
            width: 0.01rem;
            background-color: #5584A4;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0.22rem);
        }
      }
    }
    &-title {
      position: relative;
      // height: 1.65rem;
      &-icon {
        display: block;
        width: 2.52rem;
        height: 0.77rem;
        margin: 0.39rem auto;
      }
    }
    &-title::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);
      width: 0.01rem;
      height: 0.44rem;
      background-color: #fff;
    }
    &-title::before{
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 0);
      width: 0.01rem;
      height: 0.44rem;
      background-color: #fff;
    }
  }
  .draggable-o {
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: flex;
    align-items: center;
    width: 1.87rem;
    height: 0.6rem;
    background: rgba(55, 63, 98, .5);
    border-radius: 0.06rem;
    border: 0.01rem solid #ACD2E2;
    >div {
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      font-size: 0.14rem;
      color: #fff;
      cursor: pointer;
      >img {
        display: block;
        margin: auto;
        width: 0.22rem;
        height: 0.22rem;
        margin-bottom: 0.04rem;
        margin-top: 0;
      }
      >div {
        text-align: center;
      }
    }
    &-r {
      border-right: 0.01rem solid #ACD2E2;
    }
  }
}
</style>